import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import {db} from '../firebase'
import { collection, addDoc, updateDoc, doc } from "firebase/firestore"; 


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#cfe8fc',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const WarningYellow = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: 'white',
  backgroundColor: '#ffb74d',
  height: 60,
  lineHeight: '60px',
}));


export default function AlcoholRequest(props) {
  const drinkCount= props.drinks.length
  const newDrinkRequest = useRef()
  const [editOpen, setEditOpen] = useState(false);
  const handleOpen = () => setEditOpen(true);
  const handleClose = () => setEditOpen(false);

  const addNewDrink = async (name) => {
    try {
        if(name?.length === 0) return
        const newDrinkInfo =  {
            name: name,
            wants: [{name: props.currentUser?.name, id: props.currentUser?.id}]
        }
        const docRef = await addDoc(collection(db, "drinks"), newDrinkInfo)
        newDrinkInfo.id= docRef.id
        console.log("Document written with ID: ", docRef.id);
        await props.requestLatestDrinksData()
        handleClose()
      } catch (e) {
        console.error("Error adding document: ", e);
      }
}
const changeRequest = async (drinkId, want) => {
  try {
      if (!drinkId) return
      const currentDrinkWants = await props.requestLatestDrinksData().then(data => data?.find(drink => drink?.id === drinkId)?.wants)
      let newDrinkWantsList
      if (want) {
        newDrinkWantsList= currentDrinkWants?.filter(guest => guest?.id !== props?.currentUser?.id)
      } else {
        newDrinkWantsList= [...currentDrinkWants, {name: props.currentUser?.name, id:props.currentUser?.id}]
      }
      console.log(newDrinkWantsList)
      const currentDrinkDoc = doc(db, "drinks", drinkId) 
      await updateDoc(currentDrinkDoc, {
          wants: newDrinkWantsList
      }) 
    await props.requestLatestDrinksData()
    await props.requestLatestGuestData(props.currentUser?.id)
    handleClose()
  } catch (error) {
    console.log(error)
  }
}

  return (
    <>
      <div className='tableTitle'>
        Drinks Requested  ({drinkCount})
      </div>
      <TableContainer component={Paper} className='tableContainer'>
        <Table sx={{ minWidth: 50 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="center">No. People Requested</TableCell>
              <TableCell align="center">Request</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className='tableBody'>
            {props.drinks.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell className='tableRowHeader' component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="center"><Chip className={'tableCell cellNumber'} label={row?.wants?.length} /> </TableCell>
                <TableCell align="center">
                  {props.currentUser?.id !== undefined && (
                    <Button variant="contained" onClick={() => changeRequest(row?.id, row.wants.some(want => want?.id === props.currentUser?.id))} color={row.wants.some(want => want?.id === props.currentUser?.id) ? 'error' : 'success'}>{row.wants.some(want => want?.id === props.currentUser?.id) ? 'Un-Request' : 'Request'}</Button>
                  )}
                  {!props.currentUser?.id && 'User not selected'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className='newDrinkButton'>
        {props.currentUser?.id && (
          <Button variant="contained" color='primary' onClick={handleOpen}>Request New Drink</Button>
        )}
      </div>
      
      <Modal
            open={editOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Request a new drink
                </Typography>
                <TextField inputRef={newDrinkRequest} fullWidth  id="filled-basic" label="Drink Name:" variant="filled" />
                <Button onClick={() => {addNewDrink(newDrinkRequest?.current?.value)}}> Submit </Button>
            </Box>
        </Modal>
    </>
  );
}