import React, {useEffect,useState} from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import {db} from '../firebase'
import { collection, addDoc } from "firebase/firestore"; 
import Button from '@mui/material/Button';



const filter = createFilterOptions();

export const UserSelection = (props) => {
    const [value, setValue] = useState(null);

    useEffect(() => {
        if(props.currentUser && props.currentUser?.name !== value?.name){
            setValue(props.currentUser)
        }
    }, [props.currentUser])

    const addNewGuest = async (name) => {
        try {
            if(name?.length === 0) return
            const newGuestInfo =  {
                name: name,
                rsvp: false,
                white_elephant: false
            }
            const docRef = await addDoc(collection(db, "guests"), newGuestInfo)
            newGuestInfo.id= docRef.id
            console.log("Document written with ID: ", docRef.id);
            props.updateGuestList(newGuestInfo)
            setValue(newGuestInfo)
          } catch (e) {
            console.error("Error adding document: ", e);
          }
    }
    useEffect(() => {
      console.log('Value:')
      console.log(value)
      props.updateCurrentUser(value)
    }, [value])
    
    return (
        <span className='dropDownGuest'>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    console.log(newValue)
                    if (typeof newValue === 'string') {
                    setValue({
                        name: newValue
                    })
                    } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    addNewGuest((newValue.inputValue).trim() )
                    } else {
                    setValue(newValue);
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.name);
                    if (inputValue.trim() !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        name: `Add "${inputValue}"`,
                    });
                    }

                    return filtered
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={props.guests}
                getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                    return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                    return option.inputValue;
                    }
                    // Regular option
                    return option.name;
                }}
                renderOption={(props, option) => <li {...props}>{option.name}</li>}
                sx={{ width: 300 }}
                freeSolo
                renderInput={(params) => (
                    <TextField {...params} label="Select your name" />
                )}
            />
        </span>
    )
}

export default UserSelection