import React, {
  useEffect,
  useState
} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';

const rows = [
  {name: 'Bryan', 'rsvp': true, 'white_elephant': true},
  {name: 'Ice cream sandwich', 'rsvp': true, 'white_elephant': false},
  {name: 'Eclair', 'rsvp': true, 'white_elephant': false},
  {name: 'Cupcake', 'rsvp': false, 'white_elephant': false},
  {name: 'Gingerbread', 'rsvp': true, 'white_elephant': false}
];

export default function GuestListTable(props) {
  const guestCount= props.guests.length
  useEffect(() => {
    console.log(props.guests)
  }, [props.guests])
  
  return (
    <>
      <div className='tableTitle'>
        Guest List  ({guestCount})
      </div>
      <TableContainer component={Paper} className='tableContainer'>
        <Table sx={{ minWidth: 50 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="center">RSVP</TableCell>
              <TableCell align="center">White Elephant</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className='tableBody'>
            {props.guests.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell className='tableRowHeader' component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="center">{row.rsvp ? <Chip className={`tableCell ${row.rsvp ? 'tableCellConfirm' : 'tableCellDeny'}`} label="Yes" /> :  <Chip  className={`tableCell ${row.rsvp ? 'tableCellConfirm' : 'tableCellDeny'}`}  label="No" />}</TableCell>
                <TableCell align="center">{row.white_elephant ? <Chip className={`tableCell ${row.white_elephant ? 'tableCellConfirm' : 'tableCellDeny'}`} label="Yes" /> :  <Chip className={`tableCell ${row.white_elephant ? 'tableCellConfirm' : 'tableCellDeny'}`} label="No" />}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}