import { useState, useEffect } from 'react'
import Snowflakes from 'magic-snowflakes'
import { Routes, Route } from 'react-router-dom'
import Temp from './pages/Temp'
import Home from './pages/Home'
import {collection, getDocs} from 'firebase/firestore'
import {db} from './firebase'
const snowflakes = new Snowflakes()
snowflakes.start()

function App() {
  const [guests, setGuests] = useState([])
  const [drinks, setDrinks] = useState([])
  const guestsCollectionRef = collection(db, 'guests')
  const drinksCollectionRef = collection(db, 'drinks')
  const [currentUser, setCurrentUser] = useState(null)

  const getGuests = async () => {
    const data = await getDocs(guestsCollectionRef)
    console.log(data.docs)
    setGuests(data.docs.map((doc) => {
      return ({
        ...doc.data(),
        id: doc.id
      })
    }))
    return(data.docs.map((doc) => {
      return ({
        ...doc.data(),
        id: doc.id
      })
    }))
  }

  const getDrinks = async () => {
    const data = await getDocs(drinksCollectionRef)
    console.log(data.docs[0].data())
    setDrinks(data.docs.map((doc) => {
      return ({
        ...doc.data(),
        id: doc.id
      })
    }))
    return(data.docs.map((doc) => {
      return ({
        ...doc.data(),
        id: doc.id
      })
    }))
  }

  const requestLatestGuestData = async (id) => {
    // await requestLatestDrinksData()
    const latestData = await getGuests()
    console.log(latestData?.find(guest => guest?.id === id))
    updateCurrentUser(latestData?.find(guest => guest?.id === id))
    return latestData
  }
  
  const requestLatestDrinksData = async () => {
    // await requestLatestGuestData()
    const latestData = await getDrinks()
    return latestData
  }
  const updateCurrentUser = (selectedUser) => {
    if(!selectedUser?.name || !selectedUser?.id) setCurrentUser(null)
    else{
      setCurrentUser(selectedUser)
    }
  }
  const updateGuestList = (newGuest) => {
    setGuests(guests => {
      const guestList =  [...guests]
      guestList.push(newGuest)
      return(guestList)
    })
  }
  const updateDrinksList = (newDrink) => {
    setDrinks(drinks => {
      const drinksList =  [...drinks]
      drinksList.push(newDrink)
      return(drinksList)
    })
  }
  useEffect(() => {
    getGuests()
    getDrinks()
  }, [])
  


  return (
    <div>
      <Routes>
        <Route path='/' element={
          <Home
            guests={guests}
            drinks={drinks}
            updateGuestList={updateGuestList}
            updateDrinksList={updateDrinksList}
            updateCurrentUser={updateCurrentUser}
            currentUser={currentUser}
            requestLatestGuestData={requestLatestGuestData}
            requestLatestDrinksData={requestLatestDrinksData}
          />
        }/>
      </Routes>
    </div>
  );
}

export default App;
