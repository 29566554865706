import React from 'react'
import '../App.css'
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import ButtonAppBar from '../components/UserBar'
import GuestListTable from '../components/GuestListTable'
import RSVPTable from '../components/RSVPTable'
import WhiteElephantTable from '../components/WhiteElephantTable'
import EditCurrentUser from '../components/EditCurrentUser';
import AlcoholRequest from '../components/AlcoholRequest';
function Home(props) {

    return (
      <>
        <ButtonAppBar
          guests={props.guests}
          currentUser={props.currentUser}
          updateGuestList={props.updateGuestList}
          updateCurrentUser={props.updateCurrentUser}
        />
        <div className="App">
          <CssBaseline />
          <Container maxWidth="lg" className='homeContainer'>
            {props.currentUser?.name !== undefined && (
              <EditCurrentUser 
                guests={props.guests}
                currentUser={props.currentUser}
                requestLatestGuestData={props.requestLatestGuestData}
              />
            )}
            <RSVPTable
              guests={props.guests}
              currentUser={props.currentUser}
              requestLatestGuestData={props.requestLatestGuestData}
            />
            <WhiteElephantTable
              guests={props.guests}
              currentUser={props.currentUser}
              requestLatestGuestData={props.requestLatestGuestData}
            />
            <AlcoholRequest
              requestLatestGuestData={props.requestLatestGuestData}
              requestLatestDrinksData={props.requestLatestDrinksData}
              currentUser={props.currentUser}
              drinks={props.drinks}
            />
            <GuestListTable guests={props.guests} />
          </Container>

        </div>
      </>
    )
}

export default Home
