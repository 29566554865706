import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import {db} from '../firebase'
import Box from '@mui/material/Box';
import { doc, updateDoc } from "firebase/firestore";

export default function white_elephantTable(props) {
  const whiteElephantParticipants = props.guests.filter(guest => guest?.white_elephant === true)
  const currentUserDoc = props.currentUser?.id !== undefined ? doc(db, "guests", props.currentUser?.id) : null

  const changeWhiteElephantStatus = async () => {
    try {
      await updateDoc(currentUserDoc, {
        white_elephant: !props.currentUser?.white_elephant
    });
    await props.requestLatestGuestData(props.currentUser?.id)
    } catch (error) {
      console.log(error)
    }
  }
  
  const whiteElephantStatus = () => {
    if (props.currentUser?.white_elephant === undefined) {
      return 'No user selected'
    }
    else{
      return (
        <Chip className={`tableCell ${props.currentUser?.white_elephant ? 'tableCellConfirm' : 'tableCellDeny'}`} label={props.currentUser?.white_elephant ? 'Yes' : 'No' } /> 
      )
    }
  }
  return (
    <div>
      <div className= 'tableTitle'>White Elephant Participants ({whiteElephantParticipants.length})</div>
      <div className= 'tableDescription'>Your status: {whiteElephantStatus()} {props.currentUser?.white_elephant !== undefined && <Button color='secondary' onClick={changeWhiteElephantStatus}>Change</Button>}</div>
      <TableContainer component={Paper} className='tableContainer'>
        <Table sx={{ minWidth: 50 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className='tableBody'>
            {whiteElephantParticipants.map((row, index) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell className='tableRowHeader' component="th" scope="row">
                  {`${index+1}. ${row.name}`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}