import React, {useState, useEffect, useRef} from 'react'
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import {db} from '../firebase'
import { doc, updateDoc } from "firebase/firestore";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#cfe8fc',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const WarningYellow = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#ffb74d',
    height: 60,
    lineHeight: '60px',
  }));


  
function EditCurrentUser(props) {
    const [editOpen, setEditOpen] = useState(false);
    const handleOpen = () => setEditOpen(true);
    const handleClose = () => setEditOpen(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const handleDeleteOpen = () => setDeleteOpen(true);
    const handleDeleteClose = () => setDeleteOpen(false);
    const editName = useRef()
    const currentUserDoc = props.currentUser?.id !== undefined ? doc(db, "guests", props.currentUser?.id) : null
 
    const changeName = async (name) => {
        try {
            if (!name) return
            const trimmedName = String(name).trim()
            if(trimmedName.length === 0) return 
            await updateDoc(currentUserDoc, {
                name: trimmedName
            }
        ); 
        await props.requestLatestGuestData(props.currentUser?.id)
        handleClose()
        } catch (error) {
        console.log(error)
        }
    }
    return (
      <div className='editUsername'>
        <Box
            sx={{
                p: 2,
                bgcolor: 'background.default',
                gridTemplateColumns: { md: '1fr 1fr' },
                gap: 2,
            }}
        >
            <WarningYellow key={0} elevation={5} onClick={handleOpen} className='userButtons'>
                Edit Name
            </WarningYellow>
        </Box>
        <Modal
            open={editOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Current name: {props?.currentUser?.name}
                </Typography>
                <TextField inputRef={editName} fullWidth  id="filled-basic" label="Change To:" variant="filled" />
                <Button onClick={() => changeName(editName.current?.value)}> Submit </Button>
            </Box>
        </Modal>
      </div>
    )
}

export default EditCurrentUser
