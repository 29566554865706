import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { UserSelection } from '../components/UserSelection'
import logo from '../grinch.png'

export default function ButtonAppBar(props) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar className='barContainer'>
            <div> Winter Party 2022</div>
            <span className='welcomeContainer'>
                <Typography variant="h6" component="span" >
                    Welcome, 
                </Typography>
                <UserSelection
                    guests={props.guests}
                    currentUser={props.currentUser}
                    updateGuestList={props.updateGuestList}
                    updateCurrentUser={props.updateCurrentUser}
                />
            </span>
            <img src={logo} className="barLogo" alt="logo" />
        </Toolbar>
      </AppBar>
    </Box>
  );
}